import cn from 'classnames';
import s from './Topic.module.scss';
import { UniqueIdentifier, UploadedImage } from '../../../../../../../types';
import { Anchor, AnchorProps } from '../../../../../../shared/Anchor';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';

export interface TopicType {
  id: UniqueIdentifier;
  type: 'DEFAULT' | 'CUSTOM_LINK';
  name: string;
  description: string;
  link: string;
  backgroundImage: UploadedImage;
  squareImage: UploadedImage;
  featuredBrandImages: UploadedImage[];
  featuredBrandAltImages: UploadedImage[];
}

export interface TopicProps extends Pick<AnchorProps, 'className'> {
  topic: TopicType;
  featured?: boolean;
}

export const defaultProps = {
  featured: false,
};

export default function Topic({ topic, featured, className }: TopicProps) {
  const shouldInvertImages = !topic.featuredBrandAltImages?.length;
  const brandImages = shouldInvertImages
    ? topic.featuredBrandImages || []
    : topic.featuredBrandAltImages || [];

  const avatarPath = topic.squareImage?.path || topic.backgroundImage?.path;

  return (
    <Anchor
      to={topic.link}
      className={cn(s.wrapper, className, {
        [s.regular]: !featured,
        [s.featured]: featured,
        [s.withBrandImages]: brandImages.length > 0,
      })}
      data-testid="topic-anchor"
    >
      <div className={s.avatarWrapper}>
        {avatarPath ? (
          <ImageTag
            src={avatarPath}
            className={s.avatar}
            testId="topic-avatar"
          />
        ) : (
          <div className={s.fakeAvatar} />
        )}
        <div className={s.styledAvatar}>
          <span className={cn('material-icons', s.avatarIcon)}>east</span>
        </div>
      </div>
      <div className={s.body}>
        {featured && <div className={s.badge}>Popular</div>}
        <div className={s.topicName}>{topic.name}</div>
        {brandImages.length > 0 && (
          <div className={s.brandImages}>
            <div className={s.brandImagesTitle}>Featuring:</div>
            <div className={s.images}>
              {brandImages.map((brandImage, index) => (
                <ImageTag
                  key={index}
                  src={brandImage.path}
                  className={cn(s.brandImage, {
                    [s.invert]: shouldInvertImages,
                  })}
                  testId="topic-brand-image"
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </Anchor>
  );
}

Topic.defaultProps = defaultProps;
