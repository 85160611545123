import { useCallback, useState, MouseEventHandler } from 'react';
import s from './SectorsModule.module.scss';
import { HubModule, PropsWithTestId } from '../../../../../../types';
import { renderHTMLString } from '../../../../../../helpers';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import Topic, { TopicType } from './Topic';
import MobileTopic from './MobileTopic';
import { Button } from '../../../Button';

export interface SectorsModuleType extends HubModule {
  title: string;
  text: string;
  featuredTopics: TopicType[];
  linkedTopics: TopicType[];
}

export interface SectorsModuleProps {
  module: SectorsModuleType;
  home?: boolean;
}

export default function SectorsModule(
  props: PropsWithTestId<SectorsModuleProps>,
) {
  const {
    module: {
      '@type': type,
      elementId,
      visualStyle,
      title,
      text,
      featuredTopics,
      linkedTopics,
    },
    home,
    testId,
  } = props;
  const [mobileLinkedTopicsShown, setMobileLinkedTopicsShown] = useState(false);

  const handleShowMoreInterests: MouseEventHandler<HTMLAnchorElement> =
    useCallback((e) => {
      e.preventDefault();

      setMobileLinkedTopicsShown((shown) => !shown);
    }, []);

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      <div className="container">
        <div className="row">
          {home && (
            <div className="col-xs-12">
              <div className={s.homeJigsaw} data-testid="home-jigsaw" />
            </div>
          )}
          <div className="col-xs-12 col-md-8 col-md-offset-2">
            {(title || text) && (
              <div className={s.header}>
                {title && <h2 className={s.heading}>{title}</h2>}
                {text && <div className={s.text}>{renderHTMLString(text)}</div>}
              </div>
            )}
          </div>
          <div className="col-xs-12">
            <div className={s.featuredTopics}>
              {featuredTopics.map((topic) => (
                <Topic
                  key={topic.id}
                  topic={topic}
                  featured
                  className={s.featuredTopic}
                />
              ))}
            </div>
          </div>
          {linkedTopics.length > 0 && (
            <div className="col-xs-12">
              <div className="hidden-xs hidden-sm">
                <div className={s.linkedTopics}>
                  {linkedTopics.map((topic) => (
                    <Topic
                      key={topic.id}
                      topic={topic}
                      className={s.linkedTopic}
                    />
                  ))}
                </div>
              </div>
              <div className="visible-xs visible-sm">
                {mobileLinkedTopicsShown && (
                  <div className={s.mobileLinkedTopics}>
                    {linkedTopics.map((topic) => (
                      <MobileTopic key={topic.id} topic={topic} />
                    ))}
                  </div>
                )}
                <div className={s.showMobileLinkedTopicsButton}>
                  <Button
                    to="/"
                    endIcon={
                      mobileLinkedTopicsShown ? 'expand_less' : 'expand_more'
                    }
                    onClick={handleShowMoreInterests}
                  >
                    {mobileLinkedTopicsShown ? 'Close' : 'Browse all interests'}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </HubModuleWrapper>
  );
}

SectorsModule.defaultProps = {
  home: false,
  testId: 'hub-sectors-module',
};
