import s from './MobileTopic.module.scss';
import { TopicType } from '../Topic';
import { Anchor } from '../../../../../../shared/Anchor';

export interface MobileTopicProps {
  topic: TopicType;
}

export default function MobileTopic({ topic }: MobileTopicProps) {
  return (
    <Anchor to={topic.link} className={s.wrapper}>
      <div className={s.topicName}>{topic.name}</div>
      <div className={s.arrowIcon}>
        <span className="material-icons">east</span>
      </div>
    </Anchor>
  );
}
